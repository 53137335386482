import {useState, useEffect, useRef} from 'react'
import InputSelect from '../components/InputSelect/InputSelect'
import moment from 'moment'
import { shifts } from '../utils/bookings-data'
import { getOptions, getModelById } from '../services/apiService'
import { useFetcher } from 'react-router-dom'

const days = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado']

const now = new Date()

const AsyncComponent = ({model, id, onClick, removeSelf}) => {

    const [value, setValue] = useState('')

    const updateValue = async () => {

        let res = await getModelById(0, model, id)
        if (res.result==='ok') {
            setValue(res.data.name)
        } else removeSelf()
    }

    useEffect(() => {updateValue()}, [])

    return ( <span onClick={onClick} className='rule_value'>{value}</span> )

}

const PriceRuleForm = ({data, sendToUpdate, responseMessage}) => {

    const [formData, setFormData] = useState({
        id: 0,
        name: '',
        venueId: 0,
        cumulative: 0,
        web: 1,
        promotionalText: '',
        rumba: 1,
        daysInAdvance: 0,
        maxHour: 23,
        createdAt: '',
        updatedAt: '',
        availableFrom: new Date(new Date(now.getFullYear(), now.getMonth(), 1).getTime() - now.getTimezoneOffset() * 60000).toISOString(),
        availableTo: new Date(new Date(now.getFullYear(), now.getMonth() + 1, 0).getTime() - now.getTimezoneOffset() * 60000).toISOString(),
        discount: 1,
        rules: {
            noDts: [],
            noEventIds: [],
            noWeekDays: [],
            yesShiftIds: [],
            yesProductIds: []
        }
    })
    const [products, setProducts] = useState({
        options: [],
        tags: []
    })
    const changedFields = useRef(new Map())
    const rulesValues = useRef({
        noDts: [],
        noEventIds: [],
        noWeekDays: [],
        yesShiftIds: [],
        yesProductIds: []
    })

    const handleInputChange = (e) => {
        let {target: {name, value}} = e
        if (name==='discount') {
            value = value.replace(/[^0-9\.]/g, '')
        }
        setFormData((prev) => ({...prev, [name]: value}))
        changedFields.current.set(name, '')
    }

    const handleSave = () => {

        let omitKeys = ['id', 'createdAt', 'updatedAt']
        let copy = JSON.parse(JSON.stringify(formData))
        let dataToSend = {}
        Object.keys(copy).forEach(key => {

            if (!omitKeys.includes(key) && (changedFields.current.has(key) || formData.id===0 || key==='venueId')) {

                if (['web', 'rumba', 'discount', 'maxHour', 'daysInAdvance', 'cumulative', 'venueId'].includes(key))
                    dataToSend[key] = key!=='discount' ? Math.floor(Number(copy[key])) : Number(copy[key])
                else if (key==='availableFrom')
                    dataToSend[key] = copy[key].substring(0, 10) + 'T00:00-00:00'
                else if (key==='availableTo')
                    dataToSend[key] = copy[key].substring(0, 10) + 'T23:59-00:00'
                else if (key==='rules') {
                    dataToSend[key] = Object.keys(copy[key]).reduce((acc, rule) => {
                        if (copy[key][rule].length > 0) acc[rule] = copy[key][rule]
                        return acc
                    }, {})
                } else
                    dataToSend[key] = copy[key]
            }
        })
        sendToUpdate(dataToSend)        
    }

    const handleRemove = (e, value, key) => {
        changedFields.current.set('rules', '')
        setFormData((prev) => {

            let newValues = [...prev.rules[key]]
            newValues.splice(newValues.indexOf(value), 1)

            return {
                ...prev,
                rules: {
                    ...prev.rules,
                    [key]: newValues
                }
            }
        })
    }

    const handleClear = (e, key) => {
        e.preventDefault()
        setFormData((prev) => ({...prev, rules: {...prev.rules, [key]: []}}))
        changedFields.current.set('rules', '')
    }

    const handleRuleValues = (e) => {
        let {target: {name, value}} = e
        rulesValues.current[name] = Array.isArray(value) ? value : [value]
    }

    const handleAddNew = (e, key) => {
        e.preventDefault()
        changedFields.current.set('rules', '')
        setFormData((prev) => {

            return {
                ...prev,
                rules: {
                    ...prev.rules,
                    [key]: Array.from(new Set([...prev.rules[key], ...rulesValues.current[key]]))
                }
            }
        })
    }

    const getProducts = async () => {

        const products = await getOptions('productId')
        const tags = {}
        const options = []

        products.productId.forEach(p => {

            if (p.venueId===3 || p.group===p.name) return
            options.push({value: p.id, name: `${p.name}-${p.venueId===1 ? 'Cardamomo' : '1911'}`})

            if (typeof p.zone === 'string' && p.zone !== '') {
                if ('tickets' in tags) {
                    tags.tickets.values.push(Number(p.id))
                } else {
                    tags.tickets = {
                        name: 'Tickets',
                        values: [p.id],
                        icon: '',
                        active: false,
                    }
                }
            } else if (typeof p.group === 'string' && p.group !== '') {
                if (p.group in tags) {
                    tags[p.group].values.push(Number(p.id))
                } else {
                    tags[p.group] = {
                        name: p.group,
                        values: [p.id],
                        icon: '',
                        active: false,
                    }
                }
            }
        })
        setProducts({
            options: options,
            tags: Object.values(tags)
        })
    }

    useEffect(() => {
        if (data.id > 0) setFormData((prev) => ({...data, rules: { ...prev.rules, ...data.rules}}))
    }, [data])

    useEffect(() => {
        getProducts()
    }, [])

    return (

        <form className='pricing_rule_form'>
            <div>
                <div className='left_side'>
                    <div className="input_group">
                        <div>
							<p className="label">Id</p>
							<input type="text" className="form-control" id="price-rule-id" placeholder="Id" value={formData.id} disabled onChange={()=>{/* prevents the error trigger */}} />
						</div>
						<div>
							<p className="label">Nombre</p>
							<input type="text" className="form-control" id="price-rule-name" name="name" defaultValue={formData.name} onChange={handleInputChange} />
						</div>
                    </div>
                    <div className='input_group'>
                        <div>
                            <p className='label'>Descripción</p>
                            <textarea id="price-rule-description" className='form-control' name='description' value={formData.description} onChange={handleInputChange}/>
                        </div>
                    </div>
                    <div className='input_group'>
                        <div>
                            <p className='label'>Texto promocional</p>
                            <textarea id="price-rule-promotionalText" className='form-control' name='promotionalText' value={formData.promotionalText} onChange={handleInputChange}/>
                        </div>
                    </div>
                    <div className="input_group">
                        <div className='discount_container'>
                                <p className="label">{'Descuento'}</p>
                                <p >
                                    {'si: valor < 1, se tratará como porcentaje: 0.25 = 25%'}<br />
                                    {'si: valor es >= 1, se tratará como cantidad: 3 = 3€'}
                                </p>
                                <input 
                                    type="text" 
                                    id="price-rule-discount"
                                    className="form-control" 
                                    name="discount" 
                                    value={formData.discount} 
                                    onChange={handleInputChange} 
                                />							
						</div>
                    </div>
                    <div className="input_group">
                        <div>
							<p className="label">Tablao</p>
							<select name='venueId' id="price-rule-venueId" value={formData.venueId} onChange={handleInputChange}>
								<option value={0}>Todos</option>
								<option value={1}>cardamomo</option>
								<option value={2}>Villarosa 1911</option>
							</select>
						</div>
                        <div>
							<p className="label">Acumulable</p>
                            <select name='cumulative' id="price-rule-cumulative" value={formData.cumulative} onChange={handleInputChange}>
								<option value={0}>No</option>
								<option value={1}>Sí</option>
							</select>
						</div>
                    </div>
                    <div className="input_group">
                        <div>
							<p className="label">Web</p>
                            <select name='web' id="price-rule-web" value={formData.web} onChange={handleInputChange}>
								<option value={0}>No</option>
								<option value={1}>Sí</option>
							</select>
						</div>
                        <div>
							<p className="label">Rumba</p>
                            <select name='rumba' id="price-rule-rumba" value={formData.rumba} onChange={handleInputChange}>
								<option value={0}>No</option>
								<option value={1}>Sí</option>
							</select>
						</div>
                    </div>
                    <div className="input_group">
                        <div>
							<p className="label">Disponibilidad</p>
							<input
								type='date'
                                id="price-rule-availableFrom"
                                name='availableFrom'
								className="form-control" 
								value={formData.availableFrom.substring(0, 10)}
								onChange={handleInputChange}
							/>
						</div>
                        <div>
                            <p className="label"><br /></p>
							<input
								type='date'
                                name='availableTo'
                                id="price-rule-availableTo"
								className="form-control" 
								value={formData.availableTo.substring(0, 10)}
								onChange={handleInputChange}
							/>
						</div>
                    </div>
                    <div className="input_group">
                        <div>
							<p className="label">Días previos al evento</p>
							<input
								type='number'
                                name='daysInAdvance'
                                id="price-rule-daysInAdvance"
                                min={0}
								className="form-control" 
								value={formData.daysInAdvance}
								onChange={handleInputChange}
							/>
						</div>
                        <div>
                            <p className="label">Hora máxima de venta</p>
							<input
								type='number'
                                min={0}
                                max={23}
                                name='maxHour'
                                id="price-rule-maxHour"
								className="form-control" 
								value={formData.maxHour}
								onChange={handleInputChange}
							/>
						</div>
                    </div>
                </div>


                <div className='right_side'>
                    <div>
                        <p className='label'>EXCLUIR fechas</p>
                        <div className='rules_container'>
                            {   
                                formData.rules.noDts.map((v, i) => 
                                    <span onClick={(e) => handleRemove(e, v, 'noDts')} key={i} name={`noDts-${v}`} className='rule_value'>{v}</span>
                                )
                            }
                        </div>
                        <div className='rule_controls'>
                            <input
								type='date'
                                name='noDts'
                                id="price-rule-noDts"
								className="form-control" 
								onChange={handleRuleValues}
							/>
                            <button 
                                className='add' 
                                name='noDts'
                                onClick={(e) => handleAddNew(e, 'noDts')}
                            >
                                <span className='ti ti-plus'></span>
                            </button>
                            <button 
                                className='add clear' 
                                name='yesProductIds'
                                onClick={(e) => handleClear(e, 'yesProductIds')}
                            >
                                <span className='ti ti-plus'></span>
                            </button>
                        </div>
                    </div>
                    <div>
                        <p className='label'>EXCLUIR eventos</p>
                        <div className='rules_container'>
                            {   
                                formData.rules.noEventIds.map((v, i) => 
                                    <AsyncComponent key={i} id={v} model={'event'} onClick={(e) => handleRemove(e, v, 'noEventIds')} removeSelf={() => handleRemove(false, v, 'noEventIds')}/>
                                )
                            }
                        </div>
                        <div className='rule_controls'>
							<input type="text" id="price-rule-noEventIds" className="form-control" name="noEventIds" placeholder='EventId' onChange={handleRuleValues} />
                            <button 
                                className='add' 
                                name='noEventIds'
                                onClick={(e) => handleAddNew(e, 'noEventIds')}
                            >
                                <span className='ti ti-plus'></span>
                            </button>
                            <button 
                                className='add clear' 
                                name='yesProductIds'
                                onClick={(e) => handleClear(e, 'yesProductIds')}
                            >
                                <span className='ti ti-plus'></span>
                            </button>
                        </div>
                    </div>
                    <div>
                        <p className='label'>EXCLUIR días</p>
                        <div className='rules_container'>
                            {   
                                formData.rules.noWeekDays.map((v, i) => 
                                    <span onClick={(e) => handleRemove(e, v, 'noWeekDays')} key={i} name={`noWeekDays-${v}`} className='rule_value'>{days[Number(v)]}</span>
                                )
                            }
                        </div>
                        <div className='rule_controls'>
                            <InputSelect 
                                selectId='slct-noWeekDays'
                                toggleSelects={true}
                                name='noWeekDays'
                                multiSelect={true}
                                autoHide={false}
                                showCheckedValuesTag={true}
                                placeholder='Días excluidos'
                                onInput={handleRuleValues}
                                tags={[
                                    {
                                        name: 'Fines',
                                        values: [6, 0],
                                        icon: '',
                                        active: false,
                                    },
                                    {
                                        name: 'Entre',
                                        values: [1, 2, 3, 4, 5],
                                        icon: '',
                                        active: false,
                                    },
                                ]}
                                options={days.map((d, i) => ({value: i, name: d}))}
                            />
                            <button 
                                className='add' 
                                name='noWeekDays'
                                onClick={(e) => handleAddNew(e, 'noWeekDays')}
                            >
                                <span className='ti ti-plus'></span>
                            </button>
                            <button 
                                className='add clear' 
                                name='yesProductIds'
                                onClick={(e) => handleClear(e, 'yesProductIds')}
                            >
                                <span className='ti ti-plus'></span>
                            </button>
                        </div>
                    </div>
                    <div>
                        <p className='label'>INCLUIR pases</p>
                        <div className='rules_container'>
                            {   
                                formData.rules.yesShiftIds.map((v, i) => 
                                    <span onClick={(e) => handleRemove(e, v, 'yesShiftIds')} key={i} name={`yesShiftIds-${v}`} className='rule_value'>{
                                        shifts.find(s => s.value===v).name
                                    }</span>
                                )
                            }
                        </div>
                        <div className='rule_controls'>
                        <InputSelect 
                                selectId='slct-yesShiftIds'
                                name='yesShiftIds'
                                toggleSelects={true}
                                multiSelect={true}
                                autoHide={false}
                                showCheckedValuesTag={true}
                                placeholder='Pases incluidos'
                                selectAllOption={true}
                                onInput={handleRuleValues}
                                tags={[
                                    {
                                        name: 'Cardamomo',
                                        values: shifts.filter((s) => s.dependencies.venueId===1).map(s => s.value),
                                        icon: '',
                                        active: false,
                                    },
                                    {
                                        name: '1911',
                                        values: shifts.filter((s) => s.dependencies.venueId===2).map(s => s.value),
                                        icon: '',
                                        active: false,
                                    },
                                ]}
                                options={shifts}
                            />
                            <button 
                                className='add' 
                                name='yesShiftIds'
                                onClick={(e) => handleAddNew(e, 'yesShiftIds')}
                            >
                                <span className='ti ti-plus'></span>
                            </button>
                            <button 
                                className='add clear' 
                                name='yesProductIds'
                                onClick={(e) => handleClear(e, 'yesProductIds')}
                            >
                                <span className='ti ti-plus'></span>
                            </button>
                        </div>
                    </div>
                    <div>
                        <p className='label'>Aplicable a los siguientes productos</p>
                        <div className='rules_container'>
                            {   
                                formData.rules.yesProductIds.map((v, i) => {

                                    return (
                                        <span onClick={(e) => handleRemove(e, v, 'yesProductIds')} key={i} name={`yesProductIds-${v}`} className='rule_value'>{
                                            products.options.length ? products.options.find(p => p.value===v)?.name : ''
                                        }</span>
                                    )
                               })
                            }
                        </div>
                        <div className='rule_controls'>
                        <InputSelect 
                                searchable={true}
                                selectId='slct-yesProductIds'
                                name='yesProductIds'
                                toggleSelects={true}
                                multiSelect={true}
                                autoHide={false}
                                showCheckedValuesTag={true}
                                placeholder='Aplicado a'
                                selectAllOption={true}
                                onInput={handleRuleValues}
                                tags={products.tags}
                                options={products.options}
                            />
                            <button 
                                className='add' 
                                name='yesProductIds'
                                onClick={(e) => handleAddNew(e, 'yesProductIds')}
                            >
                                <span className='ti ti-plus'></span>
                            </button>
                            <button 
                                className='add clear' 
                                name='yesProductIds'
                                onClick={(e) => handleClear(e, 'yesProductIds')}
                            >
                                <span className='ti ti-plus'></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>


           {    formData.id>0 && ( 
                <div className='update_info'>
				    Actualizado: {moment(formData.updatedAt).fromNow()} | Creado: {moment(formData.createdAt).fromNow()}
                </div>
            )}
            <button type="button" className="btn rmb-btn-primary mt-3" onClick={handleSave}>
                {formData.id===0 ? 'Crear' : 'Guardar'}
            </button>
            {responseMessage && (
                <div className="mt-4 alert alert-success" role="alert">
                    {responseMessage}
                </div>
            )}
        </form>

    )
}

export default PriceRuleForm