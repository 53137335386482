import { getTrackings } from "../services/apiService";
import DataTableComponent from '../components/DataTableComponent'
import { 
    defaultFilters,
    icons,
    columnsComputedTracking,
    numberWithSpaces,
    config,
    getIconBasedOnCalc,
    defaultPreferences,
    sumTrackings,
    content
} from "../utils/tracking-data.js"
import { useState, useEffect, useRef } from 'react'
import Filters from '../components/filters/filters.js'

let firstTime = true

const TrackingPage = () => {

    const groupingBy = useRef('-')
    const comparing = useRef(false)

    const [loading, setLoading] = useState(false)
    const [groupedData, setGroupedData] = useState({
        current: [],
        last: []
    })
    const [data, setData] = useState({
        sessions: [0, 0],
        pages: [0, 0],
        sales: [0, 0],
        pax: [0, 0],
        bookings: [0, 0],
        salePerVisitor: [0, 0],
        conversion: [0, 0],
        unfinished: [0, 0]
    })
    const [filters, setFilters] = useState(defaultFilters)
    const [preferences, setPreferences] = useState({autoFilters: 0})
    const [filteredColumns, setFilteredColumns] = useState(columnsComputedTracking)
    const [groupByOptions, setGroupByOptions] = useState([
        {name: 'Ninguno', value: ''},
        {name: 'Automático', value: '-'},
        {name: 'Source', value: 'source'},
        {name: 'Campaña', value: 'campaign'},
        {name: 'Medio', value: 'medium'},
        {name: 'Grupo', value: 'group'},
    ])

    const getTrackingData = async () => {

        setLoading(true)
        const gb = groupingBy.current === '-' ?
            filters.group.length ? '' : 
		    filters.campaign.length ? 'group' :
		    filters.medium.length ? 'campaign' :
		    filters.source.length ? 'medium' :
		    'source' : groupingBy.current

        let match = {
            [gb]: true,
            sessions: true,
            pages: true,
            sales: true,
            pax: true,
            bookings: true,
            salePerVisitor: true,
            conversion: true,
            unfinished: true,
        }
        setFilteredColumns(() => columnsComputedTracking.filter(({name}) => match[name]))

        let res = await getTrackings(filters, gb)
        if (!res.data) return
        else res = res.data
        
        const copy_current = JSON.parse(JSON.stringify(res.current))
        const copy_last = JSON.parse(JSON.stringify(res.last))

        setGroupedData({
            current: Array.isArray(copy_current) ? copy_current : [copy_current],
            last: Array.isArray(copy_last) ? copy_last : [copy_last]
        })
        
        if (Array.isArray(res.current)) res.current = sumTrackings(copy_current)
        if (Array.isArray(res.last)) res.last = sumTrackings(copy_last)

        setData((p) => {

            let ns = {...p}

            ns.sessions[0] = numberWithSpaces(res.current.sessions,0) || 0
            ns.pax[0] = numberWithSpaces(Number(res.current.pax),0) || 0
            ns.pages[0] = numberWithSpaces(res.current.pages,0) || 0
            ns.sales[0] = numberWithSpaces(res.current.sales || 0) + '€'
            ns.bookings[0] = numberWithSpaces(res.current.bookings,0) || 0
            ns.salePerVisitor[0] = numberWithSpaces(res.current.sessions ? (res.current.sales / res.current.sessions) : 0) + '€'
            ns.conversion[0] = numberWithSpaces((res.current.sessions ? (res.current.bookings / res.current.sessions) : 0) * 100) + '%'
            ns.unfinished[0] = numberWithSpaces((res.current.startedBookings ? ((res.current.startedBookings - res.current.bookings) / res.current.startedBookings) : 0) * 100) + '%'

            if (Math.max(...Object.values(res.last)) === 0 || Math.max(...Object.values(res.current)) === 0) {
                Object.keys(ns).forEach(key => ns[key][1] = 0)
                comparing.current = false
                return ns
            }
            
            ns.sessions[1] = getIconBasedOnCalc('default', res.current.sessions, res.last.sessions)
            ns.pax[1] = getIconBasedOnCalc('default', res.current.pax, res.last.pax)
            ns.pages[1] = getIconBasedOnCalc('default', res.current.pages, res.last.pages)
            ns.sales[1] = getIconBasedOnCalc('default', res.current.sales, res.last.sales)
            ns.bookings[1] = getIconBasedOnCalc('default', res.current.bookings, res.last.bookings)
            ns.salePerVisitor[1] = getIconBasedOnCalc('salePerVisitor', res.current.sales, res.current.sessions, res.last.sales, res.last.sessions)
            ns.conversion[1] = getIconBasedOnCalc('conversion', res.current.bookings, res.current.sessions, res.last.bookings, res.last.sessions)
            ns.unfinished[1] = getIconBasedOnCalc('unfinished', res.current.startedBookings, res.current.bookings, res.last.startedBookings, res.last.bookings)

            return ns
        })
        
        setLoading(false)
    }

    useEffect(()=>{
        
        if (firstTime) {
            firstTime = false
            return
        }

        if (filters.dateLast[0]!=='' || filters.dateLast[1]!=='') comparing.current = true
        else comparing.current = false

        getTrackingData()
    },[filters, preferences])

    return (
        <div className='card trackings_page'>
            <div className='card-body'>
                <div className='tracking_page_header'><p className='page_title'>TRACKINGS</p></div>
                <div className="controls">
                    <Filters 
                        config={config}
                        onFilterChange={(e) => setFilters(e)}
                        onPreferenceChange={(e) => setPreferences(e)}
                        disable={loading}
                    />
                </div>
                <hr />
                <div className="trackings_container">
                    { loading && (<div className="loader_container"><span className="loader"></span></div>)}
                    { Object.keys(data).map((key, i) => 
                        <div className="trackings" key={i}>
                            <p className="comparation">
                                <span>{data[key][0]}</span>
                                {comparing.current && (
                                    <span>
                                        <span className={`ti ${icons[data[key][1]===0 ? 1 : data[key][1]>0 ? 2 : 0]}`}></span>
                                        { data[key][1]!==0 && (<span className="percentage">{data[key][1]}%</span>)}
                                    </span>
                                )}
                            </p>
                            <p className="title"><span className={`ti ${content[key].icon}`}></span>{content[key].title}</p>
                        </div>
                    )}
                </div>
                <DataTableComponent
                    title="Desglose trackings"
                    columns={filteredColumns}
                    data={groupedData.current}
                    loading={loading}
                    model="trackings"
                    sercheable={false}
                    selectConfig={{
                        name: 'trackings',
                        selectId: 'slct-trackings',
                        options: groupByOptions,
                        setOptions: setGroupByOptions,
                        onInput: (e) => {
                            groupingBy.current = e.target.value
                            getTrackingData()
                        },
                        showCurrentValue: true,
                        selectAllOption: false,
                        defaultValue: '-',
                        autoHide: true,
                        onlyOne: true,
                        toggleSelects: true
                    }}
                />
            </div>
        </div>
    )
}

export default TrackingPage